/* 
  A4 page
*/
.a4Page {
  color: var(--color-bluePen);
  font-family: var(--family-alt);
  background-image: url(/img/paper.png);
  background-repeat: repeat;
  padding: calc(var(--s-3) + 3vw);
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.3);
  font-size: 1.3em;
}
.a4Page a {
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  text-decoration: underline;
  text-decoration-style: dotted;
}
.a4Page .viewMore {
  /* These overrides aren't ideal */
  text-decoration: none;
  font-family: var(--family-main);
  font-weight: 600;
}
