/* 
  Profile pic
*/
.profilePic {
  max-width: 256px;
  margin-inline: auto;
  padding: 2px;
}
.profilePic img {
  border-radius: 290486px;
  grid-row: 1/3;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3),
    -0.5px -0.5px 0 rgba(255, 255, 255, 0.7);
}

/* 
  Welcome bit
*/
.welcomeCluster {
  align-items: center;
}
.welcomeCluster h1 {
  font-weight: 200;
  font-size: calc(1.5em + 2vw);
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
  flex: 100%;
  align-self: flex-end;
}
.welcomeCluster a {
  align-self: flex-start;
}
@media (max-width: 60ch) {
  .welcomeCluster-prefix {
    display: block;
  }
}

/* 
  Circle button
*/
.circleButton {
  color: var(--color-light);
  cursor: pointer;
  line-height: 0;
  padding: 0.35em;
  border-radius: 100%;
  font-size: 2.5em;
  border: var(--s-5) solid rgba(255, 255, 255, 0.4);
  transition: transform 300ms ease;
}
.circleButton:hover {
  transform: scale(1.05);
}
.circleButton[data-accent='twitter'] {
  background-color: #1ca1f2;
}
.circleButton[data-accent='github'] {
  background-color: black;
}
.circleButton[data-accent='dockerhub'] {
  background-color: #066da5;
}
.circleButton[data-accent='instagram'] {
  background-image: linear-gradient(-135deg, #1400c8, #b900b4, #f50000);
}
.circleButton[data-accent='mastodon'] {
  background-color: #595aff;
}
.circleButton[data-accent='scholar'] {
  background-color: #4c90fe;
}
.circleButton[data-accent='strava'] {
  background-color: #f55308;
}
.circleButton[data-accent='letterboxd'] {
  background-color: #1f282f;
}
