.projectDeck {
  width: 100%;
  background-size: cover;
  background-image: url(../img/abstract-background.jpg);
  border-radius: var(--s-1);
}
.projectDeck-header,
.projectDeck-footer {
  color: var(--color-light);
  background-color: rgba(0, 0, 0, 0.3);
  padding: var(--s-1);
  font-weight: 600;
  position: sticky;
  z-index: 9999999;
}
.projectDeck-header {
  top: 0;
  border-radius: var(--s-1) var(--s-1) 0 0;
}
.projectDeck-footer {
  bottom: 0;
  border-radius: 0 0 var(--s-1) var(--s-1);
}
.projectDeck-cards {
  min-height: 960px;
  min-width: 1048px;
  position: relative;
}
.projectDeck-header button,
.projectDeck-footer button {
  font-family: var(--family-alt);
  font-weight: 600;
  font-size: 1.2em;
  color: inherit;
  background: none;
  margin: 0;
  padding: var(--s-5) var(--s-3);
  border-radius: var(--s-5);
  border: var(--s-5) solid var(--color-light);
}

.projectDeck.isAnimating .flipCard {
  transition: top 300ms ease, left 300ms ease, opacity 300ms ease;
}

/* https://www.w3schools.com/howto/howto_css_flip_card.asp */
.flipCard {
  position: absolute;
  width: 320px;
  height: 180px;

  background-color: transparent;
  color: var(--color-dark);

  perspective: 1000px;

  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.05);
  cursor: move;
  opacity: 1;
}
.flipCard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 500ms ease;
  transform-style: preserve-3d;
  text-align: center;
}
.flipCard[data-side='back'] .flipCard-inner {
  transform: rotateY(180deg);
}
.flipCard-back,
.flipCard-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: var(--s-3);
}
.flipCard-front {
}
.flipCard-back {
  background-color: var(--color-light);
  color: var(--color-dark);
  font-weight: 600;
  transform: rotateY(180deg);
  padding: var(--s-1);
  border: var(--s-3) solid var(--color-dark);
}
.flipCard-year {
  position: absolute;
  right: var(--s-1);
  bottom: var(--s-1);
  font-weight: 400;
}
.flipCard[data-hidden='true'] {
  opacity: 0;
  pointer-events: none;
}
