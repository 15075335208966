@import 'prism-themes/themes/prism-dracula.css';

@import './globals.css';
@import './blog.css';
@import './me.css';
@import './page.css';
@import './projects.css';
@import './deck.css';
@import './now.css';

:root {
  /* ... */
  --measure: 60ch;

  --family-main: system-ui, sans-serif;
  --family-alt: Nunito, system-ui, sans-serif;
  --family-mono: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo,
    Courier, monospace;

  /* https://github.com/robb-j/r0b-design/blob/master/src/styles/base/variables.sass */
  --color-yellow: #ffdd57;
  --color-magenta: #92374d;
  --color-berry: #863a7a;
  --color-liberty: #38488d;
  /* --color-offBlue: #559cad; */
  /* --color-silver: #c0b2aa; */
  /* --color-deepKoamaru: #392b58; */
  --color-bluePen: #00188a;
  --color-blog: #377c85;

  --color-primary: var(--color-liberty);

  --color-dark: #4a4a4a;
  --color-light: white;
  --color-anchor: #3273dc;
}

/* 
  MISC
*/
.isTyping {
  padding-right: 0.1em;
  border-right: 3px solid #ee8595;
  background-color: rgba(0, 0, 0, 0.1);
}

body:not(.isGlitched) .glitched {
  display: none;
}
body.isGlitched {
  overflow: hidden;
}
.glitched {
  position: fixed;
  transition: background 3s ease;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: 4s ease glitched forwards;
  z-index: 100;
}
.glitched iframe {
  height: 100vh;
  width: 100vw;
}
@keyframes glitched {
  from {
    background: transparent;
  }
  to {
    background: black;
  }
}
