/*
  Globals
*/
html {
  font-family: var(--family-main);
  line-height: 1.4;
  color: var(--color-dark);
  font-size: 16px;
}
a {
  color: var(--color-anchor);
}
img {
  height: auto;
  max-width: 100%;
}
[aria-hidden='true'] {
  display: none;
}

/*
  Figures
*/
figure {
  max-width: 640px;
  margin: 2rem auto !important;
  text-align: center;
}
figcaption {
  font-style: italic;
}

/*
  Headings
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.125;
}

/*
  Code
*/
pre {
  font-size: 1rem;
  border-left: var(--s-2) solid #209cee;
  border-radius: var(--s-3);
}
* + pre[class*='language-'] {
  margin-top: var(--flow, 1em);
}
/* Style non-language code */
pre > code {
  font-size: 1em;
  background-color: unset;
  color: unset;
}
pre:has(code) {
  background-color: #353a43;
  color: #d6ecfc;
  padding: var(--s-1);
}
code {
  border-radius: 4px;
  padding: 0.1em 0.2em;
  margin: -0.1em 0;
  color: #353a43;
  background-color: #d6ecfc;
  font-size: 0.875em;
  font-weight: normal;
}
